import { environment } from '../environments/environment';


//let userSettingsService = new UserSettingsService();

/*export const appConfig: ApplicationConfig = {
    providers: [provideRouter(routes)]
};*/

// global constants
export const GLOBAL_CONSTANTS = {
    APP_TITLE: 'Design4Ui',
    API_ENDPOINT: environment.type === 'production' ? 'https://api.production.com' : 'https://api.development.com',
};
export const THEME_CONFIG = {
    AVAILABLE_THEMES: ['light', 'dark'],
    DEFAULT_THEME: 'light'
    //DEFAULT_THEME: userSettingsService.getTheme()
};

export const LOCALE_CONFIG = {
    AVAILABLE_LANGUAGES: ['en', 'es', 'fr', 'de'],
    DEFAULT_LANGUAGE: 'en'
};

export const AVATAR_NAME_CONFIG = {
    male: 'Tyler-insuit-20220721',
    female: 'Anna_public_3_20240108'
};

export const AVATAR_CONFIG = [
    {
        locale: 'ar-SA',
        maleName: 'Tyler-insuit-20220721',
        femaleName: 'Anna_public_3_20240108',
        femaleVoiceId: '45980606751346deaf6415a2ba6cdfde', // Fatima - Professional
        maleVoiceId: 'c0f94ec846234252aaa23b30838a0f39' // Hamdan - Natural
    },
    {
        locale: 'en-US',
        maleName: 'Tyler-insuit-20220721',
        femaleName: 'Anna_public_3_20240108',
        femaleVoiceId: '45980606751346deaf6415a2ba6cdfde',
        maleVoiceId: 'c0f94ec846234252aaa23b30838a0f39',
    },
    {
        locale: 'zh-CN',
        maleName: 'Tyler-insuit-20220721',
        femaleName: 'Anna_public_3_20240108',
        femaleVoiceId: '45980606751346deaf6415a2ba6cdfde',
        maleVoiceId: 'c0f94ec846234252aaa23b30838a0f39'
    },
    {
        locale: 'fr-FR',
        maleName: 'Tyler-insuit-20220721',
        femaleName: 'Anna_public_3_20240108',
        femaleVoiceId: '45980606751346deaf6415a2ba6cdfde',
        maleVoiceId: 'c0f94ec846234252aaa23b30838a0f39'
    },
    {
        locale: 'de-DE',
        maleName: 'Tyler-insuit-20220721',
        femaleName: 'Anna_public_3_20240108',
        femaleVoiceId: '45980606751346deaf6415a2ba6cdfde',
        maleVoiceId: 'c0f94ec846234252aaa23b30838a0f39'
    },
    {
        locale: 'ru-RU',
        maleName: 'Tyler-insuit-20220721',
        femaleName: 'Anna_public_3_20240108',
        femaleVoiceId: '45980606751346deaf6415a2ba6cdfde',
        maleVoiceId: 'c0f94ec846234252aaa23b30838a0f39'
    },
    {
        locale: 'es-ES',
        maleName: 'Tyler-insuit-20220721',
        femaleName: 'Anna_public_3_20240108',
        femaleVoiceId: '45980606751346deaf6415a2ba6cdfde',
        maleVoiceId: 'c0f94ec846234252aaa23b30838a0f39'
    },
    {
        locale: 'tr-TR',
        maleName: 'Tyler-insuit-20220721',
        femaleName: 'Anna_public_3_20240108',
        femaleVoiceId: '45980606751346deaf6415a2ba6cdfde',
        maleVoiceId: 'c0f94ec846234252aaa23b30838a0f39'
    },
    {
        locale: 'sr-RS',
        maleName: 'Tyler-insuit-20220721',
        femaleName: 'Anna_public_3_20240108',
        femaleVoiceId: '45980606751346deaf6415a2ba6cdfde',
        maleVoiceId: 'c0f94ec846234252aaa23b30838a0f39'
    },
    {
        locale: 'sr-Cyrl-RS',
        maleName: 'Tyler-insuit-20220721',
        femaleName: 'Anna_public_3_20240108',
        femaleVoiceId: '45980606751346deaf6415a2ba6cdfde',
        maleVoiceId: 'c0f94ec846234252aaa23b30838a0f39'
    },
    {
        locale: 'ur-PK',
        maleName: 'Tyler-insuit-20220721',
        femaleName: 'Anna_public_3_20240108',
        femaleVoiceId: '45980606751346deaf6415a2ba6cdfde',
        maleVoiceId: 'c0f94ec846234252aaa23b30838a0f39'
    }
];

export const LANG_CONFIG = [
    {
        name: 'عربي',
        icon: '../assets/languages/arabic.svg',
        locale: 'ar-SA',
        direction: 'rtl',
        selected: false,
    },
    {
        //name: 'Chinese',
        name: '中國人',
        icon: '../assets/languages/chinese.svg',
        locale: 'zh-CN',
        direction: 'ltr',
        selected: false,
    },
    {
        name: 'English',
        icon: '../assets/languages/english.svg',
        locale: 'en-US',
        direction: 'ltr',
        selected: true,
    },
    {
        //name: 'French',
        name: 'Français',
        icon: '../assets/languages/french.svg',
        locale: 'fr-FR',
        direction: 'ltr',
        selected: false,
    },
    {
        //name: 'German',
        name: 'Deutsch',
        icon: '../assets/languages/german.svg',
        locale: 'de-DE',
        direction: 'ltr',
        selected: false,
    },
    {
        //name: 'Russian',
        name: 'Русский',
        icon: '../assets/languages/russian.svg',
        locale: 'ru-RU',
        direction: 'ltr',
        selected: false,
    },
    {
        //name: 'Spanish',
        name: 'Español',
        icon: '../assets/languages/spanish.svg',
        locale: 'es-ES',
        direction: 'ltr',
        selected: false,
    },
    {
        //name: 'Turkish',
        name: 'Türkçe',
        icon: '../assets/languages/turkish.svg',
        locale: 'tr-TR',
        direction: 'ltr',
        selected: false,
    },
    {
        //name: 'Serbian',
        name: 'Srpski',
        icon: '../assets/languages/serbian.svg',
        locale: 'sr-RS',
        direction: 'ltr',
        selected: false,
    },
    {
        //name: 'Serbian Cyrillic',
        name: 'Српски',
        icon: '../assets/languages/serbian.svg',
        locale: 'sr-Cyrl-RS',
        direction: 'ltr',
        selected: false,
    },
    {
        name: 'Urdu',
        icon: '../assets/languages/pakistan.svg',
        locale: 'ur-PK',
        direction: 'rtl',
        selected: false
    }
];

export const TRANSLATION_CONFIG = [
    {
        locale: "ar-SA",
        typography: {
            // account
            settings: "تغيير كلمة المرور",
            logout: "تسجيل الخروج",
            // messages
            lostNet: "عذرًا، يبدو أنني معطل مؤقتًا. يرجى المحاولة مرة أخرى لاحقًا.",
            // landing page translation
            helloHeader: " مرحبًا، أنا",
            assistHeader: `كيف يمكنني مساعدتك اليوم؟`,
            // character translate
            bubbleText: [ "مرحبًا، أنا مساعدك الرقمي.", "انقر علي لبدء المكالمة." ],
            // language names translation
            /*languageNames: { "ar-SA": "عربي", "zh-CN": "صيني", "en-US": "إنجليزي", "fr-FR": "فرنسي",
                "de-DE": "ألمانية", "ru-RU": "الروسية", "es-ES": "اللغة", "tr-TR": "التركية", "sr-RS": "صربي",
                "sr-Cyrl-RS": "سربي السيريلية"},*/
            languageNames: { "ar-SA": "عربي", "zh-CN": "中國人", "en-US": "English", "fr-FR": "Français",
                "de-DE": "Deutsch", "ru-RU": "Русский", "es-ES": "Español", "tr-TR": "Türkçe", "sr-RS": "Srpski",
                "sr-Cyrl-RS": "Српски", "ur-PK": "اردو" },
            // controls translation
            currentInputPlaceholder: "اسأل سؤالاً...",
            // feedback translation
            placeholderText: 'اكتب ملاحظاتك الإضافية...',
            feedbackText: 'تقديم تعليقات إضافية',
            harmfulCheckboxText: 'هذا ضار/غير آمن',
            helpfulCheckboxText: 'هذا ليس مفيدا',
            trueCheckBoxText: 'هذا ليس صحيحا',
            buttonText: 'تقديم',
            // tooltips
            tooltipCopy: 'نسخ',
            tooltipDownload: 'تحميل CSV',
            tooltipDislike: 'استجابة سيئة',
            tooltipLike: 'استجابة جيدة',
            tooltipEdit: 'تحرير الرسالة',
            sendButton: 'إرسال',
            cancelButton: 'إلغاء',
            // related content
            relatedContentTitle: 'محتوى ذو صلة:',
            relatedQuestionsTitle: 'استكشاف المزيد',
            // change password
            changePasswordTitle: 'تغيير كلمة المرور؟',
            currentPasswordLabel: 'كلمة المرور الحالية',
            newPasswordLabel: 'كلمة المرور الجديدة',
            confirmPasswordLabel: 'تأكيد كلمة المرور الجديدة',
            changePasswordPlaceholder: 'كلمة المرور',
            changePasswordButton: 'تأكيد',
            errorRequiredMessage: 'مطلوب',
            errorMismatchMessage: 'كلمات المرور غير متطابقة.',
            aiErrorMessage: 'تسعى ExaAI لتحقيق الدقة، لكن يمكن أن تحدث أخطاء. تأكد من التفاصيل الرئيسية.',
            enableAvatarText: "تمكين المساعد الرقمي",
            clickText: "انقر فوق موافق لبدء الأفاتار.",
            okButton: "موافق",
            loadingTexts: [
                ["جاري العمل عليه!", "العثور على أفضل إجابة لك...", "تمسّك، نحن على وشك الوصول!"],
                ["أتحقق من ذلك الآن...", "أجمع المعلومات الأكثر صلة...", "أجمع التفاصيل لتوضيح الأمر..."],
                ["أجهز الأمور لك...", "أجمع التفاصيل التي تحتاجها...", "أفرز الحل الأفضل لك..."],
                ["دعني أرتب ذلك لك...", "أبحث بالضبط عما تطلبه...", "أراجع كل شيء للتأكد من الدقة..."],
                ["أفكر مليًا...", "أسترجع التفاصيل الصحيحة...", "أبحث عن ما تحتاجه بالضبط..."],
                ["دعني أفكر في ذلك مليًا...", "أجمع المعلومات...", "أقيم الخيارات بعناية..."]
            ]
        }
    },
    {
        locale: "zh-CN",
        typography: {
            // account
            settings: "更改密码",
            logout: "退出",
            // messages
            lostNet: "抱歉，看来我暂时断开连接了。请稍后再试。",
            // landing page translation
            helloHeader: "你好，我是",
            assistHeader: `今天我能怎么帮你？`,
            // character translate
            bubbleText: [ "你好，我是你的数字助手。", "点击我开始通话。" ],
            // language names translation
            /*languageNames: { "ar-SA": "阿拉伯", "zh-CN": "中國人", "en-US": "英語", "fr-FR": "法語",
                "de-DE": "德文", "ru-RU": "俄文", "es-ES": "西班牙語", "tr-TR": "土耳其", "sr-RS": "Serbian (Lat)",
                "sr-Cyrl-RS": "Serbian (Cyr)"},*/
            languageNames: { "ar-SA": "عربي", "zh-CN": "中國人", "en-US": "English", "fr-FR": "Français",
                "de-DE": "Deutsch", "ru-RU": "Русский", "es-ES": "Español", "tr-TR": "Türkçe", "sr-RS": "Srpski",
                "sr-Cyrl-RS": "Српски", "ur-PK": "اردو"},
            // controls translation
            currentInputPlaceholder: "提问...",
            // feedback translation
            placeholderText: '輸入您的附加回饋...',
            feedbackText: '提供額外的回饋',
            harmfulCheckboxText: '這是有害的/不安全的',
            helpfulCheckboxText: '這沒有幫助',
            trueCheckBoxText: '這不是真的',
            buttonText: '提交',
            // tooltips
            tooltipCopy: '复制',
            tooltipDownload: '下载 CSV',
            tooltipDislike: '不好的回应',
            tooltipLike: '好的回应',
            tooltipEdit: '编辑消息',
            sendButton: '发送',
            cancelButton: '取消',
            // related content
            relatedContentTitle: '相关内容：',
            relatedQuestionsTitle: '探索更多',
            // change password
            changePasswordTitle: '更改密码？',
            currentPasswordLabel: '当前密码',
            newPasswordLabel: '新密码',
            confirmPasswordLabel: '确认新密码',
            changePasswordPlaceholder: '密码',
            changePasswordButton: '确认',
            errorRequiredMessage: '是必需的。',
            errorMismatchMessage: '密码不匹配。',
            aiErrorMessage: 'ExaAI 旨在精确，但可能会发生错误。确认关键信息。',
            enableAvatarText: "启用数字助手",
            clickText: "点击“确定”以启动头像。",
            okButton: "确定",
            loadingTexts: [
                ["正在处理！", "为您找到最佳答案...", "稍等，我们马上就好！"],
                ["现在正在检查这个问题...", "正在收集最相关的信息...", "收集细节以使其清晰..."],
                ["正在为您准备好所有内容...", "收集您需要的详细信息...", "为您筛选出最佳解决方案..."],
                ["让我为您解决这个问题...", "正在查找您所需要的确切内容...", "审核所有内容以确保准确性..."],
                ["正在仔细思考...", "检索正确的详细信息...", "查找您需要的确切内容..."],
                ["让我仔细想一想...", "正在收集信息...", "仔细评估各种选项..."]
            ]
        }
    },
    {
        locale: "en-US",
        typography: {
            // account
            settings: "Change Password",
            logout: "Logout",
            // messages
            lostNet: "Apologies, it appears I'm momentarily disconnected. Please try again later.",
            // landing page translation
            helloHeader: "Hello, I am",
            assistHeader: `How may I assist you today?`,
            // character translate
            bubbleText: [ "Hello, I'm your Digital Assistant.", "Click on me to start a call." ],
            // language names translation
            /*languageNames: { "ar-SA": "Arabic", "zh-CN": "Chinese", "en-US": "English", "fr-FR": "French",
                "de-DE": "German", "ru-RU": "Russian", "es-ES": "Spanish", "tr-TR": "Turkish", "sr-RS": "Serbian",
                "sr-Cyrl-RS": "Serbian Cyrillic"},*/
            languageNames: { "ar-SA": "عربي", "zh-CN": "中國人", "en-US": "English", "fr-FR": "Français",
                "de-DE": "Deutsch", "ru-RU": "Русский", "es-ES": "Español", "tr-TR": "Türkçe", "sr-RS": "Srpski",
                "sr-Cyrl-RS": "Српски", "ur-PK": "اردو" },
            // controls translation
            currentInputPlaceholder: "Ask a question...",
            // feedback translation
            placeholderText: 'Type your additional feedback...',
            feedbackText: 'Provide additional feedback',
            harmfulCheckboxText: 'This is harmful/unsafe',
            helpfulCheckboxText: 'This isn’t helpful',
            trueCheckBoxText: 'This isn’t true',
            buttonText: 'Submit',
            // tooltips
            tooltipCopy: 'Copy',
            tooltipDownload: 'Download CSV',
            tooltipDislike: 'Bad response',
            tooltipLike: 'Good response',
            tooltipEdit: 'Edit message',
            sendButton: 'Send',
            cancelButton: 'Cancel',
            // related content
            relatedContentTitle: 'Related Content:',
            relatedQuestionsTitle: 'Explore More',
            // change password
            changePasswordTitle: 'Change Password?',
            currentPasswordLabel: 'Current Password',
            newPasswordLabel: 'New Password',
            confirmPasswordLabel: 'Confirm New Password',
            changePasswordPlaceholder: 'Password',
            changePasswordButton: 'Confirm',
            errorRequiredMessage: 'is required.',
            errorMismatchMessage: 'Passwords do not match.',
            aiErrorMessage: 'ExaAI aims for accuracy, but errors can occur. Confirm key details.',
            enableAvatarText: 'Enable Digital Assistant',
            clickText: 'Click OK to start Avatar.',
            okButton: 'OK',
            loadingTexts: [
                ["Working on It!", "Finding the Best Answer for You...", "Hang thight, we're almost there!"],
                ["Checking Into That Right Now...", "Collecting the Most Relevant Info...", "Gathering details to make it clear..."],
                ["Getting Things Ready for You...", "Gathering the Details You Need...", "Sorting out the best solution for you..."],
                ["Let me sort this out for you...", "Finding exactly what you’re asking for...", "Reviewing everything for accuracy..."],
                ["Thinking this through...", "Retrieving the right details...", "Finding exactly what you need..."],
                ["Let me think that through...", "Gathering the information...", "Evaluating the options carefully..."]
            ]
        }
    },
    {
        locale: "fr-FR",
        typography: {
            // account
            settings: "Changer le mot de passe",
            logout: "Déconnexion",
            // messages
            lostNet: "Désolé, il semble que je sois momentanément déconnecté. Veuillez réessayer plus tard.",
            // landing page translation
            helloHeader: "Bonjour, je suis",
            assistHeader: `Comment puis-je vous aider aujourd'hui?`,
            // character translate
            bubbleText: [ "Bonjour, je suis votre assistant numérique.", "Cliquez sur moi pour commencer un appel." ],
            // language names translation
            /*languageNames: { "ar-SA": "Arabe", "zh-CN": "Chinoise", "en-US": "Anglaise", "fr-FR": "Français",
                "de-DE": "Allemande", "ru-RU": "Russe", "es-ES": "Espagnole", "tr-TR": "Turque", "sr-RS": "Serbe",
                "sr-Cyrl-RS": "Serbe Cyrillique"},*/
            languageNames: { "ar-SA": "عربي", "zh-CN": "中國人", "en-US": "English", "fr-FR": "Français",
                "de-DE": "Deutsch", "ru-RU": "Русский", "es-ES": "Español", "tr-TR": "Türkçe", "sr-RS": "Srpski",
                "sr-Cyrl-RS": "Српски", "ur-PK": "اردو"},
            // controls translation
            currentInputPlaceholder: "Poser une question...",
            // feedback translation
            placeholderText: 'Tapez vos commentaires supplémentaires...',
            feedbackText: 'Fournir des commentaires supplémentaires',
            harmfulCheckboxText: 'Ceci est dangereux/dangereux',
            helpfulCheckboxText: 'Ce n\'est pas utile',
            trueCheckBoxText: 'Ce n\'est pas vrai',
            buttonText: 'Soumettre',
            // tooltips
            tooltipCopy: 'Copier',
            tooltipDownload: 'Télécharger CSV',
            tooltipDislike: 'Mauvaise réponse',
            tooltipLike: 'Bonne réponse',
            tooltipEdit: 'Modifier le message',
            sendButton: 'Envoyer',
            cancelButton: 'Annuler',
            // related content
            relatedContentTitle: 'Contenu Connexe:',
            relatedQuestionsTitle: 'Explorer plus',
            // change password
            changePasswordTitle: 'Changer le mot de passe ?',
            currentPasswordLabel: 'Mot de passe actuel',
            newPasswordLabel: 'Nouveau mot de passe',
            confirmPasswordLabel: 'Confirmer le nouveau mot de passe',
            changePasswordPlaceholder: 'Mot de passe',
            changePasswordButton: 'Confirmer',
            errorRequiredMessage: 'est requis.',
            errorMismatchMessage: 'Les mots de passe ne correspondent pas.',
            aiErrorMessage: `ExaAI vise l'exactitude, mais des erreurs peuvent se produire. Confirmez les détails clés.`,
            enableAvatarText: "Activer l'assistant numérique",
            clickText: "Cliquez sur OK pour démarrer l'avatar.",
            okButton: "OK",
            loadingTexts: [
                ["On s’en occupe !", "Trouver la meilleure réponse pour vous...", "Tenez bon, on y est presque !"],
                ["Je vérifie cela tout de suite...", "Je collecte les informations les plus pertinentes...", "Rassembler des détails pour rendre cela clair..."],
                ["Préparer tout pour vous...", "Rassembler les détails dont vous avez besoin...", "Trier la meilleure solution pour vous..."],
                ["Laissez-moi régler ça pour vous...", "Trouver exactement ce que vous demandez...", "Revoir tout pour plus de précision..."],
                ["Réfléchir à cela...", "Récupérer les bons détails...", "Trouver exactement ce dont vous avez besoin..."],
                ["Laissez-moi réfléchir à cela...", "Collecter les informations...", "Évaluer les options avec soin..."]
            ]
        }
    },
    {
        locale: "de-DE",
        typography: {
            // account
            settings: "Passwort ändern",
            logout: "Abmelden",
            // messages
            lostNet: "Entschuldigung, es scheint, dass ich momentan getrennt bin. Bitte versuchen Sie es später erneut.",
            // landing page translation
            helloHeader: "Hallo, ich bin",
            assistHeader: `Wie kann ich Ihnen heute helfen?`,
            // character translate
            bubbleText: [ "Hallo, ich bin Ihr digitaler Assistent.", "Klicken Sie auf mich, um einen Anruf zu starten." ],
            // language names translation
            /*languageNames: { "ar-SA": "Arabisch", "zh-CN": "Chinesisch", "en-US": "Englisch", "fr-FR": "Französisch", "de-DE": "Deutsch",
                "ru-RU": "Russisch", "es-ES": "Spanisch", "tr-TR": "Türkisch", "sr-RS": "Serbisch", "sr-Cyrl-RS": "Serbisch Kyrillisch"},*/
            languageNames: { "ar-SA": "عربي", "zh-CN": "中國人", "en-US": "English", "fr-FR": "Français",
                "de-DE": "Deutsch", "ru-RU": "Русский", "es-ES": "Español", "tr-TR": "Türkçe", "sr-RS": "Srpski",
                "sr-Cyrl-RS": "Српски", "ur-PK": "اردو" },
            // controls translation
            currentInputPlaceholder: "Eine Frage stellen...",
            // feedback translation
            placeholderText: 'Geben Sie Ihr zusätzliches Feedback ein...',
            feedbackText: 'Geben Sie zusätzliches Feedback',
            harmfulCheckboxText: 'Das ist schädlich/unsicher',
            helpfulCheckboxText: 'Das ist nicht hilfreich',
            trueCheckBoxText: 'Das ist nicht wahr',
            buttonText: 'Einreichen',
            // tooltips
            tooltipCopy: 'Kopieren',
            tooltipDownload: 'CSV herunterladen',
            tooltipDislike: 'Schlechte Antwort',
            tooltipLike: 'Gute Antwort',
            tooltipEdit: 'Nachricht bearbeiten',
            sendButton: 'Senden',
            cancelButton: 'Abbrechen',
            // related content
            relatedContentTitle: 'Verwandter Inhalt:',
            relatedQuestionsTitle: 'Mehr erkunden',
            // change password
            changePasswordTitle: 'Passwort ändern?',
            currentPasswordLabel: 'Aktuelles Passwort',
            newPasswordLabel: 'Neues Passwort',
            confirmPasswordLabel: 'Neues Passwort bestätigen',
            changePasswordPlaceholder: 'Passwort',
            changePasswordButton: 'Bestätigen',
            errorRequiredMessage: 'ist erforderlich.',
            errorMismatchMessage: 'Passwörter stimmen nicht überein.',
            aiErrorMessage: `ExaAI strebt nach Genauigkeit, aber Fehler können auftreten. Bestätigen Sie wichtige Details.`,
            enableAvatarText: "Digitalen Assistent aktivieren",
            clickText: "Klicken Sie auf OK, um den Avatar zu starten.",
            okButton: "OK",
            loadingTexts: [
                ["Wir arbeiten daran!", "Die beste Antwort für Sie finden...", "Halten Sie durch, wir sind fast da!"],
                ["Ich prüfe das gerade...", "Sammle die relevantesten Informationen...", "Sammle Details, um Klarheit zu schaffen..."],
                ["Alles für Sie vorbereiten...", "Sammle die Details, die Sie brauchen...", "Die beste Lösung für Sie finden..."],
                ["Lassen Sie mich das für Sie klären...", "Genau das finden, wonach Sie suchen...", "Überprüfe alles auf Genauigkeit..."],
                ["Darüber nachdenken...", "Die richtigen Details abrufen...", "Genau das finden, was Sie brauchen..."],
                ["Lassen Sie mich darüber nachdenken...", "Informationen sammeln...", "Die Optionen sorgfältig bewerten..."]
            ]
        }
    },
    {
        locale: "ru-RU",
        typography: {
            // account
            settings: "Изменить пароль",
            logout: "Выйти",
            // messages
            lostNet: "Извините, похоже, я на данный момент отключен. Пожалуйста, попробуйте снова позже.",
            // landing page translation
            helloHeader: "Привет, я",
            assistHeader: `Как я могу вам помочь сегодня?`,
            // character translate
            bubbleText: [ "Здравствуйте, я ваш цифровой ассистент.", "Нажмите на меня, чтобы начать звонок." ],
            // language names translation
            /*languageNames: { "ar-SA": "Арабский", "zh-CN": "Китайский", "en-US": "Английский", "fr-FR": "Французский", "de-DE": "Немецкий",
                "ru-RU": "Русский", "es-ES": "Испанский", "tr-TR": "Tурецкий", "sr-RS": "Сербский", "sr-Cyrl-RS": "Сербский кириллица" },*/
            languageNames: { "ar-SA": "عربي", "zh-CN": "中國人", "en-US": "English", "fr-FR": "Français",
                "de-DE": "Deutsch", "ru-RU": "Русский", "es-ES": "Español", "tr-TR": "Türkçe", "sr-RS": "Srpski",
                "sr-Cyrl-RS": "Српски", "ur-PK": "اردو" },
            // controls translation
            currentInputPlaceholder: "Задайте вопрос...",
            // feedback translation
            placeholderText: 'Введите дополнительный отзыв...',
            feedbackText: 'Оставьте дополнительный отзыв',
            harmfulCheckboxText: 'Это вредно/небезопасно',
            helpfulCheckboxText: 'Это бесполезно',
            trueCheckBoxText: 'Это неправда',
            buttonText: 'Отправить',
            // tooltips
            tooltipCopy: 'Копировать',
            tooltipDownload: 'Скачать CSV',
            tooltipDislike: 'Плохой ответ',
            tooltipLike: 'Хороший ответ',
            tooltipEdit: 'Редактировать сообщение',
            sendButton: 'Отправить',
            cancelButton: 'Отмена',
            // related content
            relatedContentTitle: 'Связанный контент:',
            relatedQuestionsTitle: 'Исследовать больше',
            // change password
            changePasswordTitle: 'Изменить пароль?',
            currentPasswordLabel: 'Текущий пароль',
            newPasswordLabel: 'Новый пароль',
            confirmPasswordLabel: 'Подтвердите новый пароль',
            changePasswordPlaceholder: 'Пароль',
            changePasswordButton: 'Подтвердить',
            errorRequiredMessage: 'обязательно.',
            errorMismatchMessage: 'Пароли не совпадают.',
            aiErrorMessage: `ExaAI стремится к точности, но ошибки могут возникнуть. Подтвердите ключевые детали.`,
            enableAvatarText: "Включить цифрового помощника",
            clickText: "Нажмите «ОК», чтобы запустить аватар.",
            okButton: "ОК",
            loadingTexts: [
                ["Работаем над этим!", "Подбираем для вас лучший ответ...", "Подождите немного, мы почти закончили!"],
                ["Проверяю это прямо сейчас...", "Собираю самую актуальную информацию...", "Собираю детали, чтобы всё стало ясно..."],
                ["Готовлю всё для вас...", "Собираю необходимые вам детали...", "Подбираю для вас лучшее решение..."],
                ["Позвольте мне разобраться с этим...", "Нахожу именно то, что вы ищете...", "Проверяю всё для точности..."],
                ["Думаю над этим...", "Извлекаю нужные детали...", "Нахожу именно то, что вам нужно..."],
                ["Дайте мне подумать над этим...", "Собираю информацию...", "Тщательно оцениваю все варианты..."]
            ]
        }
    },
    {
        locale: "es-ES",
        typography: {
            // account
            settings: "Cambiar la contraseña",
            logout: "Cerrar sesión",
            // messages
            lostNet: "Disculpa, parece que estoy desconectado momentáneamente. Por favor, inténtalo de nuevo más tarde",
            // landing page translation
            helloHeader: "Hola, soy",
            assistHeader: `¿Cómo puedo ayudarte hoy?`,
            // character translate
            bubbleText: [ "Hola, soy tu asistente digital.", "Haz clic en mí para iniciar una llamada." ],
            // language names translation
            /*languageNames: { "ar-SA": "Arábica", "zh-CN": "Chino", "en-US": "Inglés", "fr-FR": "Francés", "de-DE": "Alemán",
                "ru-RU": "Ruso", "es-ES": "Español", "tr-TR": "Turco", "sr-RS": "Serbio", "sr-Cyrl-RS": "Serbio Cirílico"},*/
            languageNames: { "ar-SA": "عربي", "zh-CN": "中國人", "en-US": "English", "fr-FR": "Français",
                "de-DE": "Deutsch", "ru-RU": "Русский", "es-ES": "Español", "tr-TR": "Türkçe", "sr-RS": "Srpski",
                "sr-Cyrl-RS": "Српски", "ur-PK": "اردو" },
            // controls translation
            currentInputPlaceholder: "Haz una pregunta...",
            // feedback translation
            placeholderText: 'Escriba sus comentarios adicionales...',
            feedbackText: 'Proporcionar comentarios adicionales',
            harmfulCheckboxText: 'Esto es dañino/inseguro',
            helpfulCheckboxText: 'Esto no es útil',
            trueCheckBoxText: 'Esto no es cierto',
            buttonText: 'Enviar',
            // tooltips
            tooltipCopy: 'Copiar',
            tooltipDownload: 'Descargar CSV',
            tooltipDislike: 'Mala respuesta',
            tooltipLike: 'Buena respuesta',
            tooltipEdit: 'Editar mensaje',
            sendButton: 'Enviar',
            cancelButton: 'Cancelar',
            // related content
            relatedContentTitle: 'Contenido Relacionado:',
            relatedQuestionsTitle: 'Explora más',
            // change password
            changePasswordTitle: '¿Cambiar la contraseña?',
            currentPasswordLabel: 'Contraseña actual',
            newPasswordLabel: 'Nueva contraseña',
            confirmPasswordLabel: 'Confirmar nueva contraseña',
            changePasswordPlaceholder: 'Contraseña',
            changePasswordButton: 'Confirmar',
            errorRequiredMessage: 'es obligatorio.',
            errorMismatchMessage: 'Las contraseñas no coinciden.',
            aiErrorMessage: 'ExaAI busca la precisión, pero pueden ocurrir errores. Confirme los detalles clave.',
            enableAvatarText: "Habilitar asistente digital",
            clickText: "Haz clic en OK para iniciar el avatar.",
            okButton: "OK",
            loadingTexts: [
                ["¡Trabajando en ello!", "Encontrando la mejor respuesta para ti...", "¡Espera un poco, ya casi llegamos!"],
                ["Revisando eso ahora mismo...", "Recopilando la información más relevante...", "Reuniendo los detalles para aclararlo..."],
                ["Preparando todo para ti...", "Reuniendo los detalles que necesitas...", "Ordenando la mejor solución para ti..."],
                ["Déjame resolver esto para ti...", "Encontrando exactamente lo que estás buscando...", "Revisando todo para asegurar la precisión..."],
                ["Pensando en ello...", "Recuperando los detalles correctos...", "Encontrando exactamente lo que necesitas..."],
                ["Déjame pensar en eso...", "Recopilando la información...", "Evaluando las opciones cuidadosamente..."]
            ]
        }
    },
    {
        locale: "tr-TR",
        typography: {
            // account
            settings: "Şifreyi değiştir",
            logout: "Çıkış Yap",
            // messages
            lostNet: "Özür dilerim, şu anda bağlantımın kesildiği görünüyor. Lütfen daha sonra tekrar deneyin.",
            // landing page translation
            helloHeader: "Merhaba, ben",
            assistHeader: `Bugün size nasıl yardımcı olabilirim?`,
            // character translate
            bubbleText: [ "Merhaba, ben dijital asistanınızım.", "Aramayı başlatmak için bana tıkla." ],
            // language names translation
            /*languageNames: { "ar-SA": "Arapça", "zh-CN": "Çince", "en-US": "İngilizce", "fr-FR": "Fransızca", "de-DE": "Almanca",
                "ru-RU": "Rusça", "es-ES": "İspanyol", "tr-TR": "Türkçe", "sr-RS": "Sırpça", "sr-Cyrl-RS": "Sırp Kiril Alfabesi" },*/
            languageNames: { "ar-SA": "عربي", "zh-CN": "中國人", "en-US": "English", "fr-FR": "Français",
                "de-DE": "Deutsch", "ru-RU": "Русский", "es-ES": "Español", "tr-TR": "Türkçe", "sr-RS": "Srpski",
                "sr-Cyrl-RS": "Српски", "ur-PK": "اردو" },
            // controls translation
            currentInputPlaceholder: "Bir soru sor...",
            // feedback translation
            placeholderText: 'Ek geri bildiriminizi yazın...',
            feedbackText: 'Ek geri bildirim sağlayın',
            harmfulCheckboxText: 'Bu zararlı/güvensiz',
            helpfulCheckboxText: 'Bu yararlı değil',
            trueCheckBoxText: 'Bu doğru değil',
            buttonText: 'Gönder',
            // tooltips
            tooltipCopy: 'Kopyala',
            tooltipDownload: 'CSV İndir',
            tooltipDislike: 'Kötü yanıt',
            tooltipLike: 'İyi yanıt',
            tooltipEdit: 'Mesajı düzenle',
            sendButton: 'Gönder',
            cancelButton: 'İptal',
            // related content
            relatedContentTitle: 'İlgili İçerik:',
            relatedQuestionsTitle: 'Daha fazlasını keşfet',
            // change password
            changePasswordTitle: 'Şifreyi Değiştir?',
            currentPasswordLabel: 'Mevcut Şifre',
            newPasswordLabel: 'Yeni Şifre',
            confirmPasswordLabel: 'Yeni Şifreyi Onayla',
            changePasswordPlaceholder: 'Şifre',
            changePasswordButton: 'Onayla',
            errorRequiredMessage: 'gereklidir.',
            errorMismatchMessage: 'Şifreler eşleşmiyor',
            aiErrorMessage: 'ExaAI doğruluğa odaklanır, ancak hatalar meydana gelebilir. Ana detayları onaylayın.',
            enableAvatarText: "Dijital Asistanı Etkinleştir",
            clickText: "Avatarı başlatmak için Tamam'a tıklayın.",
            okButton: "Tamam",
            loadingTexts: [
                ["Üzerinde çalışıyoruz!", "Sizin için en iyi cevabı buluyorum...", "Dayanın, neredeyse oradayız!"],
                ["Şu anda bunu kontrol ediyorum...", "En alakalı bilgileri topluyorum...", "Netleştirmek için detayları topluyorum..."],
                ["Sizin için her şeyi hazırlıyorum...", "İhtiyacınız olan detayları topluyorum...", "Sizin için en iyi çözümü sıralıyorum..."],
                ["Bunu sizin için halledeyim...", "Tam olarak ne istediğinizi buluyorum...", "Her şeyi doğruluk açısından gözden geçiriyorum..."],
                ["Bunun üzerinde düşünüyorum...", "Doğru detayları alıyorum...", "Tam olarak ihtiyacınız olanı buluyorum..."],
                ["Bunu dikkatlice düşünüyorum...", "Bilgi topluyorum...", "Seçenekleri dikkatlice değerlendiriyorum..."]
            ]
        }
    },
    {
        locale: "sr-RS",
        typography: {
            // account
            settings: "Promenite lozinku",
            logout: "Odjavite se",
            // messages
            lostNet: "Izvinjavam se, izgleda da sam trenutno bez veze. Pokušajte ponovo kasnije.",
            // landing page translation
            helloHeader: "Zdravo, ja sam",
            assistHeader: `Kako mogu da Vam pomognem danas?`,
            // character translate
            bubbleText: [ "Zdravo, ja sam Vaš digitalni asistent.", "Kliknite na mene da započnete poziv." ],
            // language names translation
            /*languageNames: { "ar-SA": "Arapski", "zh-CN": "Kineski", "en-US": "Engleski", "fr-FR": "Francuski",
                "de-DE": "Nemački", "ru-RU": "Ruski", "es-ES": "Španski", "tr-TR": "Turski", "sr-RS": "Srpski",
                "sr-Cyrl-RS": "Srpski ćirilica" },*/
            languageNames: { "ar-SA": "عربي", "zh-CN": "中國人", "en-US": "English", "fr-FR": "Français",
                "de-DE": "Deutsch", "ru-RU": "Русский", "es-ES": "Español", "tr-TR": "Türkçe", "sr-RS": "Srpski",
                "sr-Cyrl-RS": "Српски", "ur-PK": "اردو" },
            // controls translation
            currentInputPlaceholder: "Postavite pitanje...",
            // feedback translation
            placeholderText: 'Unesite dodatne povratne informacije...',
            feedbackText: 'Pružite dodatne povratne informacije',
            harmfulCheckboxText: 'Ovo je štetno/nebezbedno',
            helpfulCheckboxText: 'Ovo nije korisno',
            trueCheckBoxText: 'Ovo nije tačno',
            buttonText: 'Potvrdite',
            // tooltips
            tooltipCopy: 'Kopiraj',
            tooltipDownload: 'Preuzmi CSV',
            tooltipDislike: 'Loš odgovor',
            tooltipLike: 'Dobar odgovor',
            tooltipEdit: 'Izmenite poruku',
            sendButton: 'Pošaljite',
            cancelButton: 'Otkažite',
            // related content
            relatedContentTitle: 'Povezani sadržaj:',
            relatedQuestionsTitle: 'Istraži više',
            // change password
            changePasswordTitle: 'Promenite lozinku?',
            currentPasswordLabel: 'Trenutna lozinka',
            newPasswordLabel: 'Nova lozinka',
            confirmPasswordLabel: 'Potvrdite novu lozinku',
            changePasswordPlaceholder: 'Lozinka',
            changePasswordButton: 'Potvrdite',
            errorRequiredMessage: 'je obavezno.',
            errorMismatchMessage: 'Lozinke se ne poklapaju.',
            aiErrorMessage: 'ExaAI teži tačnosti, ali greške se mogu desiti. Potvrdite ključne detalje.',
            enableAvatarText: "Omogući digitalnog asistenta",
            clickText: "Kliknite na OK da pokrenete avatar.",
            okButton: "OK",
            loadingTexts: [
                ["Radimo na tome!", "Pronalaženje najboljeg odgovora za vas...", "Izdržite, skoro smo stigli!"],
                ["Proveravam to odmah sada...", "Prikupljam najrelevantnije informacije...", "Prikupljam detalje kako bih razjasnio..."],
                ["Pripremam sve za vas...", "Prikupljam detalje koji su vam potrebni...", "Sortiram najbolje rešenje za vas..."],
                ["Dozvolite mi da to rešim za vas...", "Pronalazim tačno ono što tražite...", "Pregledam sve radi tačnosti..."],
                ["Razmišljam o tome...", "Dohvatam prave detalje...", "Pronalazim tačno ono što vam treba..."],
                ["Pusti me da razmislim o tome...", "Prikupljam informacije...", "Pažljivo procenjujem opcije..."]
            ]
        }
    },
    {
        locale: "sr-Cyrl-RS",
        typography: {
            // account
            settings: "Промените лозинку",
            logout: "Одјавите се",
            // messages
            lostNet: "Извињавам се, изгледа да сам тренутно без везе. Покушајте поново касније.",
            // landing page translation
            helloHeader: "Здраво, ја сам",
            assistHeader: `Како могу да Вам помогнем данас?`,
            // character translate
            bubbleText: [ "Здраво, ја сам Ваш дигитални асистент.", "Кликните на мене да започнете позив." ],
            // language names translation
            /*languageNames: { "ar-SA": "Арапски", "zh-CN": "Кинески", "en-US": "Енглески", "fr-FR": "Француски",
                "de-DE": "Немачки", "ru-RU": "Руски", "es-ES": "Шпански", "tr-TR": "Турски", "sr-RS": "Српски",
                "sr-Cyrl-RS": "Српски Ћирилица" },*/
            languageNames: { "ar-SA": "عربي", "zh-CN": "中國人", "en-US": "English", "fr-FR": "Français",
                "de-DE": "Deutsch", "ru-RU": "Русский", "es-ES": "Español", "tr-TR": "Türkçe", "sr-RS": "Srpski",
                "sr-Cyrl-RS": "Српски", "ur-PK": "اردو" },
            // controls translation
            currentInputPlaceholder: "Поставите питање...",
            // feedback translation
            placeholderText: 'Унесите додатне повратне информације...',
            feedbackText: 'Пружите додатне повратне информације',
            harmfulCheckboxText: 'Ово је штетно/небезбедно',
            helpfulCheckboxText: 'Ово није корисно',
            trueCheckBoxText: 'Ово није тачно',
            buttonText: 'Потврдите',
            // tooltips
            tooltipCopy: 'Копирај',
            tooltipDownload: 'Преузми CSV',
            tooltipDislike: 'Лош одговор',
            tooltipLike: 'Добар одговор',
            tooltipEdit: 'Измените поруку',
            sendButton: 'Пошаљите',
            cancelButton: 'Откажите',
            // related content
            relatedContentTitle: 'Повезани садржај:',
            relatedQuestionsTitle: 'Истражи више',
            // change password
            changePasswordTitle: 'Промените лозинку?',
            currentPasswordLabel: 'Тренутна лозинка',
            newPasswordLabel: 'Нова лозинка',
            confirmPasswordLabel: 'Потврдите нову лозинку',
            changePasswordPlaceholder: 'Лозинка',
            changePasswordButton: 'Потврдите',
            errorRequiredMessage: 'је обавезно.',
            errorMismatchMessage: 'Лозинке се не поклапају.',
            aiErrorMessage: 'ExaAI тежи тачности, али грешке се могу десити. Потврдите кључне детаље.',
            enableAvatarText: "Омогућите дигиталног асистента",
            clickText: "Кликните на ОК да покренете аватар.",
            okButton: "ОК",
            loadingTexts: [
                ["Радимо на томе!", "Проналажење најбољег одговора за вас...", "Издржите, скоро смо стигли!"],
                ["Проверавам то одмах сада...", "Прикупљам најрелевантније информације...", "Прикупљам детаље како бих разјаснио..."],
                ["Приремам све за вас...", "Прикупљам детаље који су вам потребни...", "Сортирам најбоље решење за вас..."],
                ["Дозволите ми да то решим за вас...", "Проналазим тачно оно што тражите...", "Прегледам све ради тачности..."],
                ["Размишљам о томе...", "Дохватам праве детаље...", "Проналазим тачно оно што вам треба..."],
                ["Пусти ме да размислим о томе...", "Прикупљам информације...", "Пажљиво процењујем опције..."]
            ]
        }
    },
    {
        locale: "ur-PK",
        typography: {
            // account
            settings: "پاس ورڈ تبدیل کریں",
            logout: "لاگ آؤٹ",
            // messages
            lostNet: "معذرت، لگتا ہے کہ میں عارضی طور پر منقطع ہوں۔ براہ کرم بعد میں دوبارہ کوشش کریں۔",
            // landing page translation
            helloHeader: "سلام، میں ہوں",
            assistHeader: "میں آج آپ کی کیسے مدد کر سکتا ہوں؟",
            // character translate
            bubbleText: [ "سلام، میں آپ کا ڈیجیٹل اسسٹنٹ ہوں۔", "کال شروع کرنے کے لئے مجھ پر کلک کریں۔" ],
            // language names translation
            languageNames: { "ar-SA": "عربي", "zh-CN": "中國人", "en-US": "English", "fr-FR": "Français",
                "de-DE": "Deutsch", "ru-RU": "Русский", "es-ES": "Español", "tr-TR": "Türkçe", "sr-RS": "Srpski",
                "sr-Cyrl-RS": "Српски", "ur-PK": "اردو" },
            // controls translation
            currentInputPlaceholder: "سوال پوچھیں...",
            // feedback translation
            placeholderText: 'اپنی اضافی رائے لکھیں...',
            feedbackText: 'اضافی رائے دیں',
            harmfulCheckboxText: 'یہ نقصان دہ/غیر محفوظ ہے',
            helpfulCheckboxText: 'یہ مددگار نہیں ہے',
            trueCheckBoxText: 'یہ درست نہیں ہے',
            buttonText: 'جمع کرائیں',
            // tooltips
            tooltipDownload: 'CSV ڈاؤن لوڈ کریں',
            tooltipCopy: 'کاپی کریں',
            tooltipDislike: 'خراب جواب',
            tooltipLike: 'اچھا جواب',
            tooltipEdit: 'پیغام میں ترمیم کریں',
            sendButton: 'بھیجیں',
            cancelButton: 'منسوخ کریں',
            // related content
            relatedContentTitle: 'متعلقہ مواد:',
            relatedQuestionsTitle: 'مزید دریافت کریں',
            // change password
            changePasswordTitle: 'پاس ورڈ تبدیل کریں؟',
            currentPasswordLabel: 'موجودہ پاس ورڈ',
            newPasswordLabel: 'نیا پاس ورڈ',
            confirmPasswordLabel: 'نیا پاس ورڈ دوبارہ درج کریں',
            changePasswordPlaceholder: 'پاس ورڈ',
            changePasswordButton: 'تصدیق کریں',
            errorRequiredMessage: 'ضروری ہے۔',
            errorMismatchMessage: 'پاس ورڈز مطابقت نہیں رکھتے۔',
            aiErrorMessage: 'ExaAI درستگی کا مقصد رکھتا ہے، لیکن غلطیاں ہو سکتی ہیں۔ اہم تفصیلات کی تصدیق کریں۔',
            enableAvatarText: "ڈیجیٹل اسسٹنٹ کو فعال کریں",
            clickText: "اوتار شروع کرنے کے لیے OK پر کلک کریں۔",
            okButton: "ٹھیک ہے",
            loadingTexts: [
                ["اس پر کام جاری ہے!", "آپ کے لیے بہترین جواب ڈھونڈ رہا ہوں...", "صبر کریں، ہم تقریباً پہنچ چکے ہیں!"],
                ["ابھی اسے چیک کر رہا ہوں...", "سب سے متعلقہ معلومات اکٹھی کر رہا ہوں...", "وضاحت کے لیے تفصیلات اکٹھی کر رہا ہوں..."],
                ["آپ کے لیے چیزیں تیار کر رہا ہوں...", "وہ تفصیلات اکٹھی کر رہا ہوں جن کی آپ کو ضرورت ہے...", "آپ کے لیے بہترین حل ترتیب دے رہا ہوں..."],
                ["مجھے یہ حل کرنے دیں...", "بالکل وہی تلاش کر رہا ہوں جو آپ پوچھ رہے ہیں...", "ہر چیز کی درستگی کا جائزہ لے رہا ہوں..."],
                ["اس کے بارے میں سوچ رہا ہوں...", "درست تفصیلات حاصل کر رہا ہوں...", "بالکل وہی ڈھونڈ رہا ہوں جو آپ کو چاہیے..."],
                ["مجھے اس پر غور کرنے دیں...", "معلومات اکٹھی کر رہا ہوں...", "اختیارات کا احتیاط سے جائزہ لے رہا ہوں..."]
            ]
        }
    }
]
