<div class="box-message">
    <div class="messages-container" [ngClass]="{'talk-started': talkStarted}">
        <div class="message-wrapper" *ngFor="let message of messages; let i = index; last as isLast; first as isFirst"
             (mouseenter)="onMouseEnter(i)" (mouseleave)="onMouseLeave()">
            <div *ngIf="message.author === 'ai'" class="ai-message">
                <div class="ai-message-container">
                    <div class="image-container">
                        <img [src]="companyLogo" class="ai-icon" alt="AI icon"/>
                    </div>
                    <div class="ai-message-content">
                        <div style="display: inline-block; width: 100%;">
                            <div class="ai-text-message">
                                <div class="table-container" [innerHTML]="getFormattedMessageContent(message.content) | boldText"></div>
                            </div>
                            <div  class="message-feedback"
                                  [class.show]="hoveredMessageIndex === i ||
                                  (message.feedback === true || message.feedback === false) ||
                                  (isLast && !isFirst)" >
                                <div class="feedback-container">
                                    <div class="copy-message-container">
                                        <button id="id-copy" type="button" class="copy-button" (click)="copyTextMessage(message.content, i)"
                                        >
                                            <app-copy *ngIf="!isMessageCopied[i]"></app-copy>
                                            <app-correct *ngIf="isMessageCopied[i]"></app-correct>
                                        </button>
                                        <p *ngIf="!isMessageCopied[i]" class="button-tooltip">{{ tooltipCopy }}</p>
                                    </div>

                                    <div *ngIf="existCsv(message.content)" class="download-message-container">
                                        <button id="id-download" type="button" class="download-button" (click)="downloadCsv(message.content, i)">
                                            <app-download *ngIf="!isCsvDonwloaded[i]"></app-download>
                                            <app-correct *ngIf="isCsvDonwloaded[i]"></app-correct>
                                        </button>
                                        <p *ngIf="!isCsvDonwloaded[i]" class="button-tooltip">{{ tooltipDownload }}</p>
                                    </div>

                                    <div class="like-message-container">
                                        <button *ngIf="message.feedback === true || message.feedback === undefined" id="id-like"
                                                type="button" class="like-button" (click)="positiveReaction(i)"
                                                [ngClass]="{'clicked': isLikeButtonClicked[i]}"
                                                [disabled]="isLikeButtonClicked[i] || message.id === '' ? true : null"
                                        >
                                            <app-like></app-like>
                                        </button>
                                        <p class="button-tooltip">{{ tooltipLike }}</p>
                                    </div>
                                    <div class="dislike-message-container">
                                        <button *ngIf="message.feedback === false || message.feedback === undefined" id="id-dislike"
                                                type="button" class="dislike-button" data-bs-toggle="modal" data-bs-target="#feedbackModal"
                                                data-target=".bd-example-modal-lg" (click)="negativeReaction(i)"
                                                [ngClass]="{'clicked': isDislikeButtonClicked[i]}"
                                                [disabled]="isDislikeButtonClicked[i] || message.id === '' ? true : null"
                                        >
                                            <app-dislike></app-dislike>
                                        </button>
                                        <p class="button-tooltip">{{ tooltipDislike }}</p>
                                    </div>
                                </div>
                            </div>
                            <hr>
                        </div>
                        <div *ngIf="message.attachments && message.attachments.length > 0" class="related-content-scroll-container">
                            <app-related-content [attachments]="message.attachments" [author]="message.author"></app-related-content>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="message.author === 'client'" [ngClass]="{'edit-started': isEditing && editingMessageIndex === i}" class="client-message">
                <div class="margin-client-message">
                    <div class="client-text-message">
                        <div class="client-content-message">
                            <div *ngIf="message.attachments && message.attachments.length > 0" class="client-media-content-container">
                                <app-related-content [attachments]="message.attachments" [author]="message.author"></app-related-content>
                            </div>
                            <p [innerHTML]="message.content | boldText"></p>
                        </div>
                        <div class="editing-message-container">
                            <div class="editing-message-flex">
                                <form action="#0">
                                    <textarea appAutoResize id="id-edit-textarea" spellcheck="false" type="text" name="edit-textarea"
                                              [(ngModel)]="editingMessageContent" (keyup.enter)="sendEditedMessage(i)"
                                              (keydown.enter)="!messageFilled() && $event.preventDefault()">
                                    </textarea>
                                </form>
                                <div class="edit-buttons-container">
                                    <button id="id-cancel-edit" class="cancel-button" type="button" (click)="cancelEditing()">{{ cancelButton }}</button>
                                    <button id="id-send-edit" class="send-button" type="button" (click)="sendEditedMessage(i)"
                                            [disabled]="!messageFilled()">{{ sendButton }}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="edit-message-container">
                        <div [class.show]="hoveredMessageIndex === i" class="edit-message-wrapper">
                            <button id="id-edit-message" type="button" class="edit-message-button" (click)="editClientMessage(i, message.content)" [disabled]="!isInteractionAllowed" >
                                <app-edit></app-edit>
                            </button>
                            <p class="button-tooltip">{{ tooltipEdit }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
