import { Injectable } from '@angular/core';
import { v4 as uuidv4 } from 'uuid';
import { BehaviorSubject, lastValueFrom, Observable } from "rxjs";

import { environment } from "../../../environments/environment";
import { AVATAR_CONFIG, LANG_CONFIG } from "../../app.config";
import { HttpService } from "./http.service";
import { AgentModel } from "../models/project";
import { Language } from "../models/language";
import { UserModel } from "../models/user";
import { AvatarConfig, AvatarConfigRequest } from "../models/avatarConfig";


@Injectable({
    providedIn: 'root'
})
export class ConfigService {
    config!: AgentModel
    agentId: string | null = null;
    languages: Language[] = LANG_CONFIG;
    avatarConfigs: AvatarConfig[] = AVATAR_CONFIG;

    url: string = `${this.getHttpScheme()}://${this.getHost()}/api`;
    avatarConfigRequest: BehaviorSubject<AvatarConfigRequest> = new BehaviorSubject<AvatarConfigRequest>({ name: '', voiceId: '' });
    characterIcon: BehaviorSubject<string> = new BehaviorSubject<string>('');
    avatarGender: BehaviorSubject<string> = new BehaviorSubject<string>('');

    constructor(private http: HttpService) {}

    //obrisi
    getUrl() {
        return this.url;
    }

    getToken(){
        return localStorage.getItem("token");
    }

    // getters environment
    getHost () {
        return environment.apiUrl;
    }

    getOrchestrationHost () {
        return environment.orchestratorUrl;
    }

    getDesignType() {
      return environment.design;
    }

    getWssScheme () {
        return environment.wssScheme;
    }

    getHttpScheme () {
        return environment.httpScheme;
    }

    getDevice(): string {
        return window.innerWidth <= 992 ? 'mobile' : 'desktop';
    }

    // getters config
    getLanguages() {
        return this.config.languages;
    }

    getShortcuts(language: string) {
        return this.config.shortcuts
            .filter(s => s.language.locale === language).map(shortcut => ({ text: shortcut.name })) ?? [];
    }

    getProjectName(): string {
        return this.config.name;
    }

    getAgentName(): string {
        return this.config.name;
    }

    //getters
    getAgentId(): string | null {
        return this.agentId;
    }

    getAnonymousId(): string {
        const declare = (key: string): string => {
            let id = localStorage.getItem(key) ?? uuidv4();
            localStorage.setItem(key, id);
            return id;
        }

        return declare("anonymousId");
    }

    getDesignId(): string {
        return '3d41f6bc-ad1e-47fe-822d-c0653c22a3f8';
    }

    getAvatarEnabled(): boolean {
        //this.config.avatarEnabled = false;
        return this.config.avatarEnabled;
    }

    getCharacterIcon(): Observable<string> {
        return this.characterIcon.asObservable();
    }

    getCurrentAvatarGender(): Observable<string> {
        return this.avatarGender.asObservable();
    }

    getAvatarConfig(gender: string, locale: string): any {
        let avatarConfigRequest;

        const avatarConfig = this.avatarConfigs.find(config => config.locale === locale);

        if (avatarConfig) {
            if (gender === 'male') {
                avatarConfigRequest = { name: avatarConfig.maleName, voiceId: avatarConfig.maleVoiceId }
            }
            else if (gender === 'female') {
                avatarConfigRequest = { name: avatarConfig.femaleName, voiceId: avatarConfig.femaleVoiceId }
            }
        }
        else {
            avatarConfigRequest = {
                name: '',
                voiceId: ''
            }
        }

        return avatarConfigRequest;
    }

    getAvatarConfigRequest(): Observable<AvatarConfigRequest> {
        return this.avatarConfigRequest;
    }

    getAvatarTalkOptions(): string[] {
        //return this.config.avatarTalk;
        const avatarTalk = [];
        const avatarTalkAll = ["text", "voice", "call"];

        return avatarTalkAll;
    }

    //setters
    setUser(user: UserModel) {
        localStorage.setItem("user", JSON.stringify(user));
    }

    setAgentId(id: string | null) {
        this.agentId = id;
    }

    setAgentConfig (config: AgentModel) {
        this.config = config;
        this.setSelectedLanguage('en-US');
    }

    setSelectedLanguage (locale: string) {
        this.config.languages = this.config.languages.map(language => {
            return {
                id: language.id,
                name: language.name,
                icon: ((): string => { const l = this.languages.find((l: any) => l.locale === language.locale); return l?.icon ?? ""; })(),
                locale: language.locale,
                direction: ((): string => { const l = this.languages.find((l: any) => l.locale === language.locale); return l?.direction ?? "ltr"; })(),
                selected: language.locale === locale,
                presenter_url: '', //language.presenter_url,
                order: language.order,
                voices: this.config.voices.map(voice => voice.languageLocale === language.locale ? voice : null)
            }
        })
    }

    setAvatarConfigRequest(config: AvatarConfigRequest): void {
        this.avatarConfigRequest.next(config);
    }

    setCharacterIcon() {
        const robotIcon = 'assets/icons/robot.svg';
        const maleAvatarIcon = 'assets/icons/maleAvatar.svg';
        const femaleAvatarIcon = 'assets/icons/femaleAvatar.svg';

        this.getCurrentAvatarGender().subscribe(gender => {
            if (this.getAvatarEnabled() && gender) {
                if (gender === 'male') {
                    this.characterIcon.next(maleAvatarIcon);
                } else if (gender === 'female') {
                    this.characterIcon.next(femaleAvatarIcon);
                }
            } else {
                this.characterIcon.next(robotIcon);
            }
        });
    }

    setCurrentAvatarGender(gender: string) {
        return this.avatarGender.next(gender);
    }

    isMobileDevice(): boolean {
        // @ts-ignore
        const userAgent = navigator.userAgent || navigator.vendor || window['opera'];

        // Check if the User-Agent matches common mobile device keywords
        const isMobileUserAgent = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent);

        // Check if the screen size is typical of mobile devices
        const isSmallScreen = window.innerWidth <= 768;

        // Check if the device supports touch events
        const isTouchDevice = 'ontouchstart' in window || navigator.maxTouchPoints > 0;

        return isMobileUserAgent || isSmallScreen || isTouchDevice;
    }

    // methods
    async fetchConfiguration () {
        try {
            return await lastValueFrom(
                this.http
                .setHost(`${this.getHttpScheme()}://${this.getHost()}/api/agent/${this.getAgentId()}/get`)
                .setMethod("GET")
                .setHeaders({
                    "content-type": "application/json"
                })
                .create()
            );
        } catch (e: any) {
            throw new Error(`could not get required configuration: ${e.toString()}`);
        }
    }
}
