import { Component, OnDestroy, OnInit, } from '@angular/core';
import { Subscription } from "rxjs";
import { Clipboard } from "@angular/cdk/clipboard";
import { saveAs } from 'file-saver';

import { MessageService } from "../../../core/services/message.service";
import { EventService } from "../../../core/services/event.service";
import { LanguageService } from "../../../core/services/language.service";
import { MessageDisplayFormat } from "../../../core/models/messageDisplayFormat";
import { InteractionService } from "../../../core/services/interaction.service";

@Component({
    selector: 'app-message',
    templateUrl: './message.component.html',
    styleUrl: './message.component.scss'
})
export class MessageComponent implements OnInit, OnDestroy {
    companyLogo: string = "assets/icons/logo.svg";
    tooltipCopy: string = 'Copy';
    tooltipDownload: string = 'Download CSV';
    tooltipLike: string = 'Good response';
    tooltipDislike: string = 'Bad response';
    tooltipEdit: string = 'Edit message';
    cancelButton: string = 'Cancel';
    sendButton: string = 'Send';

    hoveredMessageIndex: number | null = null;

    editingMessageIndex: number | null = null;
    isMessageCopied: boolean[] = [];
    isCsvDonwloaded: boolean[] = [];
    messages: MessageDisplayFormat[] = [];
    isDislikeButtonClicked: boolean[] = [];
    isLikeButtonClicked: boolean[] = [];
    talkStarted: boolean = false;
    isEditing: boolean = false;
    editingMessageContent!: string;
    isInteractionAllowed: boolean = false;
    csvLink: string = '';

    translateSubscription!: Subscription;
    talkSubscription!: Subscription;
    interactionSubscription!: Subscription;
    editSubscription!: Subscription;

    relatedContentTitle: string = 'Related Content:';

    constructor(
        private event: EventService,
        protected message: MessageService,
        private language: LanguageService,
        private clipboard: Clipboard,
        private interaction: InteractionService
    ) { }

    ngOnInit(): void {
        this.messages = this.message.getMessages();

        this.interactionSubscription = this.interaction.getInteractionMode().subscribe(interactionAllowed => {
            this.isInteractionAllowed = interactionAllowed;
        });

        this.event.dislikeButtonClicked.subscribe(index => {
            this.isDislikeButtonClicked[index] = false;
        });

        this.event.feedbackSubmitted.subscribe(index => {
            this.isDislikeButtonClicked[index] = true;
        });

        this.talkSubscription = this.event.getStartedTalk().subscribe(isTalkStarted => {
            this.talkStarted = isTalkStarted;
        });

        this.editSubscription = this.event.getStartedEdit().subscribe(isEditing => {
            this.isEditing = isEditing;
        });

        this.translateSubscription = this.language.selectedLanguage$.subscribe((selectedLanguage) => {
            const translate = this.language.getDesignTranslation(selectedLanguage.locale)
            Object.assign(this, translate.typography);
        });
    }

    // getters
    getFormattedMessageContent(content: string): string {
        const csvPattern = /```?csv\n([\s\S]*?)\n```/g;
        let resultHtml = '';
        let lastIndex = 0;

        content.replace(csvPattern, (match, csvContent, offset) => {
            resultHtml += content.slice(lastIndex, offset);
            lastIndex = offset + match.length;

            const rows = csvContent.split('\n').map((row: any) => row.split(',').map((cell: any) => cell.trim()));
            const headers = rows.shift();
            let tableHtml = `<table class="csv-table"><thead><tr><th>No.</th>`;
            headers?.forEach((header: any) => {
                tableHtml += `<th>${header}</th>`;
            });
            tableHtml += `</tr></thead><tbody>`;
            rows.forEach((row: any, index: any) => {
                tableHtml += `<tr><td>${index + 1}</td>` + row.map((cell: any) => `<td>${cell}</td>`).join('') + `</tr>`;
            });
            tableHtml += `</tbody></table>`;

            resultHtml += `<div class="csv-table-wrapper">${tableHtml}</div>`;
            return match;
        });

        resultHtml += content.slice(lastIndex);
        return resultHtml;
    }

    getCSVDownload(content: string): string[] {
        const urls: string[] = [];

        for (const match of this.matchAllCSV(content)) {
            if (match[1]) {
                const csvContent = match[1];
                const blob = new Blob([csvContent], { type: 'text/csv' });
                const url = URL.createObjectURL(blob);
                urls.push(url);
            }
        }

        return urls;
    }

    onMouseEnter(index: number): void {
        this.hoveredMessageIndex = index;
    }

    onMouseLeave(): void {
        this.hoveredMessageIndex = null;
    }

    copyTextMessage(content: string, index: number) {
        const cleanedContent = content
        .replace(/```?csv\n([\s\S]*?)\n```/g, (match, csvContent) => {
            const rows = csvContent.split('\n').map((row: string) => row.split(',').map((cell: string) => cell.trim()));
            return rows.map((row: any) => row.join(' ')).join('\n') + '\n';
        })
        .replace(/\*\*(.*?)\*\*/g, '$1');

        this.clipboard.copy(cleanedContent);
        this.isMessageCopied[index] = true;

        setTimeout(() => {
            this.isMessageCopied[index] = false;
        }, 3000);
    }

    downloadCsv(content: string, i: number) {
        const csvUrls = this.getCSVDownload(content);

        csvUrls.forEach((url, index) => {
            saveAs(url, `data-${index + 1}.csv`);
            this.isCsvDonwloaded[index] = true;

            setTimeout(() => {
                this.isCsvDonwloaded[index] = false;
            }, 3000);
        });
    }

    positiveReaction(index: number) {
        this.message.setMessageFeedback(index, true);
        this.isLikeButtonClicked[index] = true;
    }

    negativeReaction(index: number) {
        this.message.setMessageIndex(index);
        this.event.blurEfectEvent.emit(true);
        this.event.dislikeButtonClicked.emit(index);
    }

    editClientMessage(index: number, content: string) {
        this.event.setStartedEdit(true);
        this.editingMessageIndex = index;
        this.editingMessageContent = content;
    }

    async sendEditedMessage(index: number) {
        if (!this.messageFilled()) return;
        await this.message.editMessage(index, this.editingMessageContent);
        this.resetFields();
    }

    cancelEditing() {
        this.event.setStartedEdit(false);
        this.editingMessageIndex = null;
    }

    existCsv(content: string): boolean {
        return /```?csv\n([\s\S]*?)\n```/.test(content);
    }

    matchAllCSV(content: string) {
        const csvPattern = /```?csv\n([\s\S]*?)\n```/g;
        return  content.matchAll(csvPattern);
    }

    createTableFromCSV(csvContent: string): string {
        const rows = csvContent.split('\n');
        const tableRows = rows.map(row => {
            const columns = row.split(',');
            const tableCells = columns.map(col => `<td>${col.trim()}</td>`).join('');
            return `<tr>${tableCells}</tr>`;
        }).join('');

        return `<table border="1" class="tabela-nova" style="border-collapse: collapse; width: 100%;">
                ${tableRows}
            </table>`;
    }

    getCsvTable(content: string) {
        const csvPattern = /```?csv\n([\s\S]*?)\n```/;
        const match = content.match(csvPattern);
        if (match) {
            const csvContent = match[1];
            const rows = csvContent.split('\n');
            return rows.map(row => row.split(',').map(col => col.trim()));
        }
        return [];
    }

    getCsvData(content: string): string[][] {
        const csvPattern = /```?csv\n([\s\S]*?)\n```/;
        const match = content.match(csvPattern);
        if (match) {
            const csvContent = match[1];
            const rows = csvContent.split('\n');
            return rows.map(row => row.split(',').map(cell => cell.trim()));
        }
        return [];
    }

    messageFilled(): boolean {
        return !!(this.editingMessageContent && this.editingMessageContent.trim() !== "");
    }

    resetFields() {
        this.editingMessageContent = "";
        this.event.setStartedEdit(false);
        this.editingMessageIndex = null;
    }

    ngOnDestroy() {
        if (this.translateSubscription) { this.translateSubscription.unsubscribe(); }
        if (this.talkSubscription) { this.talkSubscription.unsubscribe(); }
        if (this.interactionSubscription) { this.interactionSubscription.unsubscribe(); }
        if (this.editSubscription) { this.editSubscription.unsubscribe(); }
    }
}
